.digestListView {
    display: flex;
    flex-direction: column;
    /* Vertical scrolling layout */
    gap: 10px;
    /* Space between items */
    overflow-y: hidden;
    /* Enable scrolling */
    max-height: 100%;
    width: 100%;
    /* Prevent overflow */
    position: absolute;
    top: 0;
    z-index: 2;
}

.digestListViewContent {
    overflow-y: auto;
    width: 100%;
}

.listItem {
    margin-bottom: 1rem;
}

.filterContainer {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    /* Add some spacing below the buttons */
}

.filterButton {
    padding: 8px 16px;
    border: 1px solid #2F1C0A;
    border-radius: 16px;
    background-color: #FBEFDA;
    cursor: pointer;
    transition: background-color 0.05s ease, color 0.05s ease;
}

.activeFilter {
    background-color: #FFA929;
    /* Highlight active button */
}