.counterContainer {
  display: flex;
  background-color: white;
  border-radius: 0.5rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.counterButton {
  padding: 0;
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.counterButton:hover {
  background-color: #f0f0f0;
}

.counterValue {
  font-size: 1.0625rem;
  color: #2F1C0A;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.icon {
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
}