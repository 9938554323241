.childInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.childInfoTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputField {
  padding: 0.94rem;
  text-align: center;
  border-radius: 0.5rem;
  background: white;
  font-size: 1.0625rem;
}

.radioGroup {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.5rem;
  gap: 0.25rem;
  /* Add space between the buttons */
  background-color: #fff;
  padding: 0.38rem;
  box-sizing: border-box;
}

.radioInput {
  display: none;
  /* Hide the default radio input */
}

.radioLabel {
  padding: 0.56rem;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 1.4875rem */
  text-align: center;
  width: 100%;
  background-color: #fff;
  /* Default background */
  border-radius: 0.5rem;
  /* Make the edges rounded */
  cursor: pointer;
  transition: all 0.3s ease;
  /* Smooth hover effect */
  color: #7A7A7A;
}

.selected {
  background: var(--Plii-Secondary, #2F1C0A);
  /* Selected button background */
  color: #fff;
  /* Selected button text color */
}

.calendarGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  /* Ensure relative positioning for the dropdown */
  overflow: visible;
  /* Allow dropdowns to render properly */
}

.datePicker {
  width: 100%;
  box-sizing: border-box;
  padding: 0.94rem;
  font-size: 1.0625rem;
  min-height: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 1.4875rem */
  text-align: center;
  border-radius: 0.5rem;
  /* Make the edges rounded */
  position: relative;
  /* Ensure relative positioning for the dropdown */
  overflow: visible;
  /* Allow dropdowns to render properly */
  z-index: 0;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.continueButton,
.backButton {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.continueButton {
  background-color: #007bff;
  color: white;
}

.backButton {
  background-color: #f0f0f0;
  color: #333;
}

.continueButton:active,
.backButton:active {
  transform: scale(0.98);
}

.typeaheadModal {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 600px;
  z-index: 1001;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
}