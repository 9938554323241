.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.eventTitle {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    text-align: left;
    flex-grow: 1;
    /* Ensures the title takes up available space */
}

.eventTime {
    font-size: 0.875rem;
    font-weight: normal;
    color: #666;
    text-align: right;
    white-space: nowrap;
    /* Prevents the time from wrapping */
}

/* New Styles for Attention Levels */
.informational {
    border-left: 5px solid #6c757d;
    /* Gray */
    background: #f8f9fa;
}

.mayNeedAction {
    border-left: 5px solid #ffc107;
    /* Yellow */
    background: #fffbea;
}

.actionRequired {
    border-left: 5px solid #dc3545;
    /* Red */
    background: #ffe6e6;
}