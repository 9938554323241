.dateInputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.94rem;
  }
  
.dateField {
    width: 100%; /* Equal width for MM/DD */
    font-size: 1.0625rem;
    text-align: center;
    border-radius: 0.5rem;
    padding:  0.94rem 1.56rem;
    outline: none;
}

.dateField:focus {
    border-color: #007bff;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
}

.dateSeparator {
    font-size: 1.25rem;
    color: #aaa;
}
