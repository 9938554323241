.footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerContainer .actionButton {
    padding-top: 0.94rem;
    appearance: none !important;
    /* Reset platform-specific styling */
    outline: none !important;
    /* Remove any outline */
    border: none !important;
    /* Ensure no border styling */
    background: none !important;
    /* Remove any background */
    -webkit-tap-highlight-color: transparent !important;
    /* Disable tap highlight on mobile */
    width: auto;
    /* Allow the width to fit the content */
    height: auto;
    /* Allow the height to fit the content */
    border: none;
    /* Optional: Remove border if unnecessary */
    background: none;
    /* Optional: Remove background color */
    display: inline-flex;
    /* Align to image dimensions */
    align-items: center;
    /* Center content vertically */
    justify-content: center;
    /* Center content horizontally */
    cursor: pointer;
    transition: transform 0.05s cubic-bezier(0.4, 0.0, 0.2, 1), background-color 0.05s ease-in-out;
}

.footerContainer .actionButton:active {
    /* Pressed state background */
    transform: scale(0.95) !important;
    /* Slightly shrink for a pressed effect */
    outline: none !important;
    /* Prevent outline during active state */
    box-shadow: none !important;
    /* Prevent shadow effects */
}

.footerContainer .actionButton:focus {
    outline: none !important;
    /* Prevent the blue outline */
    box-shadow: none !important;
    /* Prevent box shadow effects */
    background: none !important;
    /* Prevent any background color */
}

.footerContainer .actionButton:disabled {
    pointer-events: none;
    opacity: 0.4;
}


.footerContainer .actionButton {
    /* Remove any padding */
    width: auto;
    /* Allow the width to fit the content */
    height: auto;
    /* Allow the height to fit the content */
    border: none;
    /* Optional: Remove border if unnecessary */
    background: none;
    /* Optional: Remove background color */
    display: inline-flex;
    /* Align to image dimensions */
    align-items: center;
    /* Center content vertically */
    justify-content: center;
    /* Center content horizontally */
    cursor: pointer;
    /* Ensure it behaves like a button */
}