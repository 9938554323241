.timeDivider {
    margin-left: 0.3rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 140%;
    margin-right: 0.3rem;
}

.timeContainer {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.38rem;
    border-radius: 0.5rem;
    width: 100%;
    box-sizing: border-box;
}

.timeInput {
    color: var(--Plii-Secondary, #2F1C0A);
    text-align: center;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    width: 100%;
    /* 1.225rem */
}

.dateInput {
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    width: 100%;
    border: none;
    caret-color: transparent;
    padding: 0.5rem 0;
    border-radius: 0.5rem;
    box-sizing: border-box;
    text-align: center;
}

.dateInput:focus {
    background-color: rgba(47, 28, 10, 0.1);
    /* Focus behavior */
    outline: none;
    /* Remove default browser outline */
}

.datePickerPopper {
    z-index: 10;
    max-width: 100%;
    font-family: Inter;
    /* Prevent overflow */
}

.datePickerContainer {
    width: 100%;
    border-radius: 0.5rem;
    /* Prevents the DatePicker from growing too large */
}