.addSheetHeader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1.75rem;
    border-bottom: 1px solid #2F1C0A;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 0.75rem;
    border: 2px solid #2F1C0A;
    background: #D1B7FF;
}

.attentionIcon {
    width: 1.84613rem;
    height: 1.84613rem;
    flex-shrink: 0;
}

.headerInstruction {
    text-align: center;
    color: var(--Plii-Secondary, #2F1C0A);
    text-align: center;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    margin-top: 1.75rem;
    /* 1.16875rem */
}

.sectionHeader {
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    margin: 1rem 0;
    /* 1.16875rem */
}

.inputSection .input {
    color: #000;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.4rem */
    width: 100%;
    padding: 0.94rem;
    border: 2px solid var(--Plii-Secondary, #fff);
    border-radius: 0.5rem;
    box-sizing: border-box;
    text-align: left;
    align-items: left;
}

.inputSection .input:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid var(--Plii-Secondary, #2F1C0A);
}