.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.25rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    max-height: 40vh;
    /* Fixed height for the container */
    overflow: hidden;
    /* Ensure scrolling only happens inside the table */
}

.scrollableContent {
    flex-grow: 1;
    overflow-y: auto;
    /* Enable vertical scrolling */
    padding-right: 1rem;
    /* Add padding for scrollbars */
}

.calendarRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem;
    /* 10px */
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid #eee;
    /* 8px */
}

.calendarInfo {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.05rem;
    cursor: pointer;
}

.typeDropdown {
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    padding: 1px;
    width: 5rem;
    /* Ensure consistent dropdown width */
}

select:focus {
    outline: 2px solid blue;
    /* Example focus style */
}