/* CaretakerDropdown.module.css */
.dropdown {
    position: relative;
    width: 100%;
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
}

.optionRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0.38rem 0;
    box-sizing: border-box;
    transition: "all 0.3s ease-in-out";
}

.optionRow:hover {
    background-color: #2F1C0A;
    color: #fff;
}


.profilePicture {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    object-fit: cover;
}

.name {
    margin-left: 0.55rem;
}

.caretakerContainer {
    border-radius: 0.5rem;
    background-color: #fff;
    padding: 0.38rem;
}