.container {
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    border-radius: 1rem;
    background: #2F1C0A;
    /* Plii Card */
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.50);
    position: relative;
}

.bottomSheetContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* Ensures content stays within the parent */
}

.bottomSheet {
    background: linear-gradient(180deg, #FFA929 0%, #FFA929 100%);
    border-radius: 5rem 5rem 1rem 1rem;
    border: 2px solid var(--Plii-Secondary, #2F1C0A);
    padding: 1.75rem 1rem;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.3s ease-out;
    pointer-events: auto;
    height: auto;
    /* Allow interactions within the ActionSheet */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.topSection {}

.bottomSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.bottomSection .input {
    width: 100%;
    padding: 0.94rem;
    border: none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    text-align: left;
    align-items: left;
}

.iconContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.confirmationIcon {
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
}

.infoSection {
    text-align: center;
    margin-top: 2rem;
    padding-bottom: 1.75rem;
    border-bottom: 1px solid var(--Plii-Secondary, #2F1C0A);
    margin-bottom: 0.75rem;
}

.sectionHeader {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 1.16875rem */
}

.notes {
    border-radius: 0.5rem;
    background: #FFF;
    padding: 1rem;
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    min-height: 6.125rem;
    width: 100%;
    box-sizing: border-box;
    /* 1.4rem */
}

.submitButton {
    border-radius: 3rem;
    border: 1.5px solid var(--Plii-Secondary, #2F1C0A);
    background: var(--Plii-Secondary, #2F1C0A);
    width: 100%;
    padding: 12px;
    cursor: pointer;
    color: #FBEFDA;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-top: 1.75rem;
    /* 1.4rem */
    transition: background-color 0.2s ease-in-out;
}

.secondaryButton {
    border-radius: 3rem;
    border: 1.5px solid var(--Plii-Secondary, #2F1C0A);
    background: #FBEFDA;
    width: 100%;
    padding: 12px;
    cursor: pointer;
    color: #2F1C0A;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-top: 0.62rem;
    /* 1.4rem */
    transition: background-color 0.2s ease-in-out;
}

.submitButton:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}