.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.error {
    margin-top: 8px;
    color: red;
    font-size: 14px;
    text-align: center;
}