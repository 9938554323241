.actionSheetContainer {
    height: 100%;
    width: 100%;
    position: relative;
    text-align: center;
    /* Ensures content stays within the parent */
}

.actionSheet {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background: linear-gradient(180deg, #FFA929 0%, #FFA929 100%);
    border-radius: 5rem 5rem 1rem 1rem;
    border: 2px solid var(--Plii-Secondary, #2F1C0A);
    padding: 2rem;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.3s ease-out;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    pointer-events: auto;
    /* Allow interactions within the ActionSheet */
}

.actionSheetNoAnimation {
    animation: none;
    /* Disable animation */
}

.actionsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.rowButtons {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.selectionButton {
    border-radius: 3rem;
    border: 1.5px solid var(--Plii-Secondary, #2F1C0A);
    background: #FBEFDA;
    color: var(--Plii-Secondary, #2F1C0A);
    text-align: center;
    font-family: Inter, sans-serif;
    width: 100%;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4rem;
    /* Matches 140% line-height */
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.selectionButton:hover {
    background-color: #fff5e6;
}

.closeButton {
    background: transparent;
    color: var(--Plii-Secondary, #2F1C0A);
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    display: inline-flex;
    z-index: 1000;
}

.closeButton:hover {
    color: #ff7043;
}

.primaryButton {
    border-radius: 3rem;
    border: 1.5px solid var(--Plii-Secondary, #2F1C0A);
    background: #2F1C0A;
    color: #FBEFDA;
    text-align: center;
    font-family: Inter, sans-serif;
    width: 100%;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4rem;
    /* Matches 140% line-height */
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.primaryButton:disabled {
    opacity: 0.6;
}


@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

.overlay {
    position: fixed;
    top: -10vh;
    left: -10vw;
    width: 110vw;
    height: 110vh;
    background: #FFF;
    opacity: 0.8;
    z-index: 990;
    /* Ensure it is below the ActionSheet */
    pointer-events: all;
    /* Allow interaction with the ActionSheet */
    /* Prevent interaction with the overlay */
}