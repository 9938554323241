.screenView {
    height: 100%;
    /* Ensures the container grows within the parent */
    display: flex;
    flex-direction: column;
    padding-top: 0;
    flex-grow: 1;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    background-color: var(--Plii-Background);
}

.content {
    flex: 1;
    padding: 0 1.37rem;
    /* Ensures the content takes up the remaining space below the Navbar */
}

.overflowHiddenContent {
    display: flex;
    flex-direction: column;
}

.overflowAuto {
    overflow: visible;
    overflow-x: clip;
    flex-grow: 1;
    height: auto;
    overscroll-behavior: contain;
    /* Prevent bouncing effect */
    -webkit-overflow-scrolling: touch;
    /* Smooth scrolling on iOS */
    /* Full viewport height */
    background: var(--Plii-Background);
    transition: height 0.2s ease-out;
    transition: transform 0.3s ease-out;
    /* Smooth transition when resizing */
    /* Enable vertical scrolling if content overflows */
    margin-top: 3rem;
}

.overflowHidden {
    overflow: hidden;
    /* Hide vertical overflow */
}

.hidden {
    display: none;
}

.footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.37rem;
    padding-top: 0;
    /* Pushes the footer to the bottom */
    text-align: center;
    /* Center align content */
}

.noPadding {
    padding: 0;
}

.iosBottomPadding {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    height: env(safe-area-inset-top);
    background-color: var(--Plii-Background);
    /* Match your navbar background */
    z-index: 1000;
    /* Ensures it's above content */
    pointer-events: none;
    /* Prevents interactions */
}

.footer.primary {
    color: black;
}

.iosSafeAreaPadding {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    height: env(safe-area-inset-top);
    background-color: #FBEFDA;
    /* Match your navbar background */
    z-index: 1000;
    /* Ensures it's above content */
    pointer-events: none;
    /* Prevents interactions */
}

/* When keyboard is visible, move up UI smoothly */
.keyboardVisible {
    transition: transform 0.3s ease-out;
    /* overflow-y: hidden;
    overscroll-behavior: none; */
}

input:focus,
textarea:focus {
    background-color: #FFF;
    border-color: #FFA929;
}

input,
textarea {
    transition: all 0.3s ease-in-out;
    caret-color: black;
}

/* 
input[type="text"],
input[type="email"] {
    padding: 0.94rem;
    text-align: center;
    background: white;
    border-radius: 0.5rem;
    border: none;
    font-size: 1rem;
    color: #2F1C0A;
} */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFF;
    opacity: 0.8;
    z-index: 990;
    /* Ensure it is below the ActionSheet */
    pointer-events: all;
    /* Allow interaction with the ActionSheet */
    /* Prevent interaction with the overlay */
}