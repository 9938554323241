/* Base styles for ActionButton */
.secondaryButton {
    width: 100%;
    /* Adjusted padding */
    margin-top: 0.94rem;
    font-size: 0.75rem;
    /* 18px for better readability */
    font-weight: 600;
    line-height: 140%;
    /* Rounded edges */
    background: transparent;
    /* White background */
    /* Black text */
    cursor: pointer;
    border: none;
    transition: all 0.3s ease;
    /* Smooth hover effect */
}

.secondaryButton.blackText {
    color: black;
}

/* Disabled button styles */
.disabled {
    background: #e0e0e0;
    /* Light gray */
    color: #a0a0a0;
    /* Muted text color */
    cursor: not-allowed;
}

/* Hover effect for enabled buttons */
.secondaryButton:not(.disabled):hover {
    color: gray;
    /* Slightly darker shade */
}