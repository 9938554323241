.navigationMenu {
    width: 100%;
    background: var(--Plii-Background, #f1daba);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
}

.closeButton {
    position: absolute;
    top: 16px;
    left: 16px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #2f1c0a;
}

.fillPaddingProfileRow {
    background-color: var(--Plii-Secondary);
}

.logo {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    font-family: "Inter";
}

.menuSection {
    margin-top: 20px;
}

.menuCategory {
    font-size: 18px;
    font-weight: bold;
    color: #2f1c0a;
    margin-bottom: 10px;
}

.menuItem {
    font-size: 16px;
    color: #2f1c0a;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid #2f1c0a;
}

.menuItem:hover {
    background: rgba(47, 28, 10, 0.1);
}