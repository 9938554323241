/* Base styles for ActionButton */
.actionButton {
    width: 100%;
    padding: 0.75rem 3rem;
    /* Adjusted padding */
    font-size: 1.125rem;
    /* 18px for better readability */
    font-weight: 600;
    border-radius: 5rem;
    /* Rounded edges */
    background: #2F1C0A;
    /* White background */
    color: white;
    /* Black text */
    cursor: pointer;
    border: none;
    transition: all 0.3s ease;
    /* Smooth hover effect */
    display: flex;
    align-items: center;
    justify-content: center;
}

.lightBrown {
    color: #2F1C0A;
    border-radius: 5rem;
    background: #FFA929;
}

/* Disabled button styles */
.disabled {
    opacity: 0.5;
    /* Muted text color */
    cursor: not-allowed;
}

@media (min-width: 992px) {

    /* Hover effect for enabled buttons */
    .actionButton:not(.disabled):hover {
        opacity: 0.8;
        /* Slightly darker shade */
    }
}

.actionButton:not(.disabled):focus,
.actionButton:not(.disabled):active {
    opacity: 1;
}

.icon {
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 1rem;
    display: inline-block;
    vertical-align: middle;
    object-fit: contain;
    /* Ensure the content scales proportionally */
    max-width: 100%;
    /* Prevent overflow */
    max-height: 100%;
    /* Prevent overflow */
}

.appleIcon {
    position: relative;
    /* Allows fine-tuning with top or bottom */
    top: -2px;
    /* Adjust this value to shift the icon up */
    margin-right: 0.625rem;
    /* Maintain consistent spacing to the text */
    width: 1.25rem;
    /* 20px */
    height: 1.25rem;
    /* 20px */
    display: inline-block;
    vertical-align: middle;
    /* Align with text */
    object-fit: contain;
    /* Maintain aspect ratio */
}