/* Terms and Conditions Styles - TermsAndConditions.css */

.terms-conditions {
    max-width: 800px;
    margin: 0 auto;
    padding: 60px 20px;
    color: #333;
    background-color: #f9f9f9;
    line-height: 1.6;
  }
  
  .terms-conditions h1 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
    color: #4CAF50;
  }
  
  .terms-conditions p,
  .terms-conditions div {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-conditions iframe {
    display: block;
    width: 100%;
    border: none;
  }
  
  /* Back Button */
  .back-button {
    background-color: #ff6f61;
    color: #fff;
    padding: 10px 20px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .back-button:hover {
    background-color: #e65b50;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .terms-conditions {
      padding: 40px 15px;
    }
  
    .terms-conditions h1 {
      font-size: 30px;
    }
  
    .terms-conditions p,
    .terms-conditions div {
      font-size: 16px;
    }
  }
  