.profileDetailContainer {
    border-radius: 0.5rem;
    background: #FFF;
    position: relative;
    width: 100%;
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.38rem 0.81rem;
    box-sizing: border-box;
}

.profilePicture {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    object-fit: cover;
}

.name {
    margin-left: 0.55rem;
}