.profileMenuRowContainer {
    border-radius: 1.25rem 1.25rem 0rem 0rem;
    background: #2F1C0A;
    color: #FBEFDA;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.37rem;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    z-index: 1001;
}

.leftContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rightContainer {}

.profilePicture {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    object-fit: cover;
}

.name {
    margin-left: 1rem;
}