/* General styles for the login page */
.loginPage {
  display: flex;
  flex-direction: column;
}

.logoContainer {
  text-align: center;
  margin-top: auto;
  margin-bottom: 0.5rem;
  /* 30px */
}

.logo {
  width: 12.49975rem;
  height: auto;
  margin-bottom: 0.625rem;
  /* 10px */
}

.linkDecoration {
  color: #2F1C0A;
  font-family: Inter;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.tagline {
  font-size: 1.0625rem;
  /* 12px */
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  line-height: 140%;
  margin-top: 0.625rem;
  /* 10px */
  margin-bottom: auto;
}

.termsContainer {
  margin-top: auto;
  text-align: center;
}

.termsText {
  font-size: 0.625rem;
  /* Slightly less than 1rem */
  font-weight: 400;
  line-height: 140%;
  text-align: center;
}

.termsText a {
  /* Light blue for links */
  text-decoration: underline;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  /* 15px */
  margin-top: 0.5rem;
}

.signupEmailButton,
.signupGoogleButton,
.loginButton {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 140%;
}

.signupEmailButton {
  background-color: #2F1C0A;
  color: white;
}

.signupGoogleButton {
  background-color: #2F1C0A;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.googleIcon {
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 1rem;
}

.loginButton {
  background: var(--Plii-Background, #F1DABA);
  color: #2F1C0A;
  border: 1.5px solid #2F1C0A;
}

/* Responsive styles for smaller screens */
@media (max-width: 48rem) {

  /* 768px */
  .logo {
    width: 12.49975rem;
  }

  .signupEmailButton,
  .signupGoogleButton,
  .loginButton {
    font-size: 0.9rem;
    /* 14.4px */
    padding: 0.75rem;
    /* 12px */
  }
}