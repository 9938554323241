.digestStackedView {
    position: relative;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

.card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 16px;
    will-change: transform, opacity;
}

.topCard {
    z-index: 2;
    cursor: grab;
}

.nextCard {
    z-index: 1;
    pointer-events: none;
    /* Disable interaction for the next card */
}