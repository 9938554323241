.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: var(--Plii-Background);
    /* Matches the beige background color */
    color: #2F1C0A;
    /* Dark brown text */
    text-align: center;
    padding: 1.5rem;
    flex-grow: 1;
}

.logo {
    width: 6.25rem;
    height: 4.17281rem;
    flex-shrink: 0;
    /* Adjust size as needed */
    margin-bottom: 2rem;
}

.message {
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    text-align: center;
    margin-bottom: 1.75rem;
}

.progressBarContainer {
    width: 100%;
    height: 0.75rem;
    background: var(--Plii-Secondary, #2F1C0A);
    border-radius: 2.5rem;
    overflow: hidden;
    margin-bottom: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: start;
}

.progressBar {
    margin-left: 0.12rem;
    height: 66%;
    max-width: 99%;
    border-radius: 2.5rem;
    background: var(--Plii-Primary, #FF9637);
    /* Matches the brown progress bar */
    transition: width 0.3s ease;
}

.percentage {
    color: var(--Plii-Secondary, #2F1C0A);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    margin: 0 auto;
    /* Matches text color */
}