/* General styles for the login screen */
.loginScreen {
  height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  /* Adjusted padding for proper alignment */
  box-sizing: border-box;
  /* Ensure padding doesn't overflow */
}

.loginHeader {
  position: relative;
  /* Enables absolute positioning for the title */
  width: 100%;
  height: 3rem;
  /* Define the header height (adjust if needed) */
  display: flex;
  align-items: center;
}

.prevButton {
  position: absolute;
  /* Position the back button absolutely */
  left: 0;
  /* Keep it on the left */
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 1rem;
  /* Add some padding for clickable area */
}

.loginTitle {
  position: absolute;
  /* Ensure the title stays in the center */
  left: 50%;
  transform: translateX(-50%);
  /* Center horizontally */
  font-size: 1.25rem;
  /* Adjust title size */
  font-weight: bold;
  text-align: center;
  font-family: 'Inter';
}

.loginForm {
  display: flex;
  flex-direction: column;
  /* Space between form elements */
  margin-top: 6.2rem;
  /* Add extra space below the header */
}

.resetWebForm {
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
}

.signupForm {
  display: flex;
  flex-direction: column;
  /* Space between form elements */
}

.verifyEmailContainer {
  margin-top: 1.25rem;
}

.instructions {
  color: #2F1C0A;
  font-family: Inter;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 1.4875rem */
  margin-top: 4.95rem;
  margin-bottom: 3rem;
}

.nativeResetInstructions {
  color: #2F1C0A;
  font-family: Inter;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 1.4875rem */
  margin-bottom: 3rem;
}

.webResetInstructions {
  color: #2F1C0A;
  font-family: Inter;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 1.4875rem */
  margin-bottom: 3rem;
}

.loginForm .loginInput {
  padding: 1rem;
  /* Add padding inside the input */
  font-size: 1.0625rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  border: 1.5px solid #2F1C0A;
  background: var(--Plii-Background);
  color: #2F1C0A;
  outline: none;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}

.loginForm .loginInput:focus {
  border: 1.5px solid #FFA929;
  background: #FFF;
}

.loginInput::placeholder {
  color: #2F1C0A;
}

.forgotPasswordLink {
  background: none;
  border: none;
  color: #2F1C0A;
  text-align: left;
  font-size: 0.75rem;
  /* Slightly smaller font size */
  cursor: pointer;
  /* Adjust link position for better spacing */
  font-weight: 500;
  line-height: 140%;
  /* 1.05rem */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.formLoginButton {
  margin-top: 3rem;
}

.loginDivider {
  text-align: center;
  margin: 1rem 0;
  /* Space above and below the divider */
  font-size: 1rem;
  /* Lighter color for divider */
}

.googleLoginButton {
  width: 100%;
  padding: 1rem;
  font-size: 1.125rem;
  border-radius: 5rem;
  border: 1px solid #2F1C0A;
  /* Border for Google login */
  background: var(--Plii-Background, #F1DABA);
  color: #2F1C0A;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  /* Space between icon and text */
  font-weight: bold;
  cursor: pointer;
}

.googleIcon {
  width: 1.25rem;
  /* 20px */
  height: 1.25rem;
}

.loginLink {
  color: var(--Plii-Secondary, #2F1C0A);
  font-family: Inter;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.resendText {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #2F1C0A;
}

.resendLink {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.emailBoldText {
  color: #2F1C0A;
  font-family: Inter;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.headerLogo {
  width: 4.49338rem;
  height: 3rem;
  flex-shrink: 0;
}

.logoContainerHeaderText {
  color: var(--Plii-Secondary, #2F1C0A);
  font-family: Inter;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 1.4875rem */
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.existingLoginFooter {
  margin-top: 3.5rem;
  margin-bottom: env(safe-area-inset-bottom);
}