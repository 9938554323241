.requestViewContainer {
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    background: #FBEFDA;
}

.infoBold {
    margin-top: 0.38rem;
    font-weight: 700;
    padding-bottom: 0.38rem;
}

.infoSection {
    border-top: 1px solid var(--Plii-Secondary, #2F1C0A);
    /* Top border */
    border-bottom: 1px solid var(--Plii-Secondary, #2F1C0A);
    /* Bottom border */
    border-left: none;
    /* Ensures no left border */
    border-right: none;
    /* Ensures no right border */
    margin-top: 1.38rem;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    line-height: 120%;
    text-align: center;
}

.infoSectionDividerBottom {
    width: 100%;
    padding: 0.06rem;
    align-self: stretch;
}

.requestViewHeader {
    margin-top: 1.75rem;
    padding-bottom: 0.75rem;
}

.timeRowContainer {
    margin-top: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.sectionHeader {
    line-height: 110%;
    margin-top: 1rem;
}

.caretakerDropdownContainer {
    margin-top: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.messageInput {
    width: 100%;
    height: 6.125rem;
    resize: none;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    font-family: inherit;
    margin-top: 1rem;
    background-color: #fff;
    box-sizing: border-box;


}

.messageInput:active {
    border: none;
}


.submitButton {
    border-radius: 3rem;
    border: 1.5px solid var(--Plii-Secondary, #2F1C0A);
    background: var(--Plii-Secondary, #2F1C0A);
    width: 100%;
    padding: 12px;
    cursor: pointer;
    color: #FBEFDA;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-top: 1.75rem;
    /* 1.4rem */
    transition: background-color 0.2s ease-in-out;
}

.submitButton:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}