.timeDetails {
    color: var(--Plii-Secondary, #2F1C0A);
    text-align: center;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    width: 100%;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: none;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.94rem 0;

}

.startTimeContainer {
    background-color: #fff;
    color: #2F1C0A;
    border: "none";
    border-radius: 0.5rem;
    box-shadow: "none";
    padding: 0.1rem 0;
    width: 100%;
    box-sizing: border-box;
}

.endTimeContainer {
    background-color: #fff;
    color: #2F1C0A;
    border: "none";
    border-radius: 0.5rem;
    box-shadow: "none";
    padding: 0.1rem 0;
    width: 100%;
    box-sizing: border-box;
}

.timeDivider {
    margin-left: 0.3rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 140%;
    margin-right: 0.3rem;
}