.navbar {
    /* Enables absolute positioning for the title */
    width: 100%;
    height: 3.2rem;
    /* Define the header height (adjust if needed) */
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    background-color: var(--Plii-Background);
}

.overflowHidden {
    position: relative;
    padding: 0.75rem 0.38rem;
}

.overflowVisible {
    position: fixed !important;
    top: calc(env(safe-area-inset-top, 0px));
    padding: 0.38rem;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: none;
    padding-top: 0.75rem;
    /* Support for notched devices */
}

.mobileTop {
    top: env(safe-area-inset-top);
}

.hidden {
    display: none;
}

.backButtonContainer {
    margin-left: 0;
    padding-left: 1.37rem;
    align-items: center;
    display: flex;
}

.closeButtonContainer {
    margin-left: 0;
    padding-left: 1.13rem;
    align-items: center;
    display: flex;
}

.prevButton {
    /* Position the back button absolutely */
    all: unset;
    /* Reset all button default styles */
    display: inline-flex;
    /* Ensure button shrinks to fit the content */
    align-items: center;
    /* Center the image vertically if necessary */
    justify-content: center;
    /* Center the image horizontally if necessary */
    width: auto;
    /* Let the button width adapt to the image */
    height: auto;
    /* Let the button height adapt to the image */
    cursor: pointer;
    /* Keep the pointer behavior */
}

.backButtonIcon {
    width: 2.25rem;
    height: 2.25rem;
}

.title {
    position: absolute;
    /* Use absolute positioning to center it */
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
    /* Offset by 50% of its width */
    text-align: center;
    font-family: 'Inter';
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.logo {
    position: absolute;
    /* Use absolute positioning to center it */
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
    width: 3.37006rem;
    height: 2.25rem;
}

.imageHidden {
    visibility: hidden;
}