.onboardingStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.onboardingStepContentGroup {
  width: 100%;
}

.onboardingStepProgress {
  margin-top: 3rem;
  display: flex;
  justify-content: left;
  width: 100%;
  align-items: center;
  color: var(--Plii-Secondary, #2F1C0A);
  /* Adjust spacing between dots */
}

.dot {
  width: 0.375rem;
  height: 0.375rem;
  opacity: 0.6;
  background-color: var(--Plii-Secondary, #2F1C0A);
  /* Default color */
  border-radius: 50%;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  /* Smooth transition */
}

.activeDot {
  color: var(--Plii-Secondary, #2F1C0A);
  font-family: "GT Maru Mega Trial";
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-right: 0.75rem;
  /* 3.75rem */
  /* width: 1rem; */
  /* Larger size for the active step */
  /* height: 1rem;
  background-color: #fff; */
  /* Highlight color */
}

.onboardingStepTitle {
  width: 97%;
  text-align: left;
  margin-top: 2.5rem;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.onboardingStepSubTitle {
  width: 97%;
  text-align: left;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-top: 0.25rem;
}


.onboardingStepContent {
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 400px;
}

.onboardingStepSkip {
  background: none;
  /* Transparent button */
  color: #bbb;
  /* Light grey text */
  border: none;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
}

.onboardingStepSkip:hover {
  color: #fff;
  /* Highlight on hover */
}