.instructions {
    margin-top: 4.95rem;
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 3rem;
    /* 1.4875rem */
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0;
    border-radius: 0.5rem;
    background: #FFF;
    max-height: 40vh;
    /* Fixed height for the container */
    overflow: hidden;
    /* Ensure scrolling only happens inside the table */
    color: #2F1C0A;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.225rem */
}

.scrollableContent {
    flex-grow: 1;
    overflow-y: auto;
}

.calendarRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
    /* 10px */
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid #2F1C0A;
    /* 8px */
}

.lastRow {
    border-bottom: none;
    padding-bottom: 1rem;
}

.calendarInfo {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.05rem;
    cursor: pointer;
}

.typeDropdown {
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    padding: 1px;
    width: 5rem;
    /* Ensure consistent dropdown width */
}

select:focus {
    outline: 2px solid blue;
    /* Example focus style */
}

.checkboxInput {
    appearance: none;
    width: 0.9375rem;
    height: 0.9375rem;
    flex-shrink: 0;
    background-color: white;
    border-radius: 0.25rem;
    border: 1.5px solid #2F1C0A;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxInput:focus {
    border: 1.5px solid #2F1C0A;
}


.checkboxInput:checked {
    background-color: #2F1C0A;
    /* Change to any color */
    border-color: #2F1C0A;
}

.checkboxInput:checked::after {
    content: url("../../assets/icons/checkMark.svg");
    color: #FFA929;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.62rem;
}