.calendarSyncContainer {
    padding: 0.125rem 1rem;
    /* 20px */
    border-radius: 0.5rem;
    /* 8px */
    background-color: #FFF;
    color: #2F1C0A;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    min-height: 3.25rem;
    vertical-align: middle;
    margin: 0 auto;
    /* 1.225rem */
}

.accountRow {
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
    min-height: 1.5rem;
    padding: 0.625rem;
    /* 10px */
    padding-right: 0;
    border-bottom: 1px solid #2F1C0A;
}

.lastAccountRow {
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
    min-height: 1.5rem;
    padding: 0.625rem;
    /* 10px */
    padding-right: 0;
    border-bottom: none;
}

.disabledRow {
    opacity: 0.5;
}

.accountProvider {
    margin-right: 0.625rem;
    /* 10px */
}

.accountEmail {
    flex-grow: 1;
}

.removeButton {
    padding: 0.28125rem;
    border-radius: 0.75rem;
    background: var(--Plii-Secondary, #2F1C0A);
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    border: none;
}

.refreshIcon {
    width: 0.9375rem;
    height: 0.9375rem;
}

.refreshButton {
    padding: 0.3125rem 0.37rem;
    border-radius: 1.875rem;
    background: #FFA929;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border: none;
    color: #2F1C0A;
    font-family: Inter;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin-right: 0.5rem;
    /* 0.625rem */
}

.calendarAddButton {
    background: #FBEFDA;
    color: var(--Plii-Secondary, #2F1C0A);
    border-radius: 5rem;
    border: 1.5px solid var(--Plii-Secondary, #2F1C0A);
}

.xicon {
    width: 0.9375rem;
    height: 0.9375rem;
    flex-shrink: 0;
}

.icon {
    width: 1.25rem;
    /* 20px */
    height: 1.25rem;
    /* 20px */
    margin-right: 0.625rem;
    /* 10px */
    display: inline-block;
    vertical-align: middle;
    object-fit: contain;
    /* Ensure the content scales proportionally */
    max-width: 100%;
    /* Prevent overflow */
    max-height: 100%;
    /* Prevent overflow */
}

.appleIcon {
    position: relative;
    /* Allows fine-tuning with top or bottom */
    top: -2px;
    /* Adjust this value to shift the icon up */
    margin-right: 0.625rem;
    /* Maintain consistent spacing to the text */
    width: 1.25rem;
    /* 20px */
    height: 1.25rem;
    /* 20px */
    display: inline-block;
    vertical-align: middle;
    /* Align with text */
    object-fit: contain;
    /* Maintain aspect ratio */
}