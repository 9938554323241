/* Privacy Policy Styles - PrivacyPolicy.css */

.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 60px 20px;
    color: #333;
    background-color: #f9f9f9;
    line-height: 1.6;
  }
  
  .privacy-policy h1 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
    color: #4CAF50; /* Matches the sign-up button color */
  }
  
  .privacy-policy p,
  .privacy-policy div {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Privacy Policy Script Embed Style */
  .privacy-policy div[name="termly-embed"] {
    display: block;
    margin: 20px 0;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .privacy-policy {
      padding: 40px 15px;
    }
  
    .privacy-policy h1 {
      font-size: 30px;
    }
  
    .privacy-policy p,
    .privacy-policy div {
      font-size: 16px;
    }
  }
  
  /* Back Button */
.back-button {
    background-color: #ff6f61; /* Match navbar button color */
    color: #fff;
    padding: 10px 20px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .back-button:hover {
    background-color: #e65b50;
  }