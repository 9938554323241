.inputWrapper {
    /* position: relative; */
    /* margin-bottom: 1.125rem; */
    text-align: left;
    width: 100%;
    box-sizing: border-box;
}

.loginInput {
    padding: 1rem;
    /* Add padding inside the input */
    font-size: 1.0625rem;
    border-radius: 0.5rem;
    border: 1.5px solid #2F1C0A;
    background: var(--Plii-Background);
    color: #2F1C0A;
    outline: none;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.inputField {
    padding: 1rem;
    /* Add padding inside the input */
    font-size: 1.0625rem;
    border-radius: 0.5rem;
    border: none;
    background: #FFF;
    color: #2F1C0A;
    outline: none;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.loginInput:focus,
.inputField:focus {
    border: 1.5px solid #FFA929;
    background: #FFF;
}

.loginInput::placeholder,
.inputField::placeholder {
    color: #2F1C0A;
    opacity: 0.3;
}

.loginInputError,
.inputError {
    border: 1.5px solid var(--Plii-Red);
}

.errorMessage {
    /* position: absolute;
    white-space: nowrap; */
    min-height: 1rem;
    color: var(--Plii-Red);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-top: 0.0625rem;
    margin-bottom: 0.125rem;
    align-items: flex-start;
    display: flex;
    /* Optional: Show "..." if text is too long */
}

.errorIcon {
    width: 0.75rem;
    height: 0.75rem;
    flex-shrink: 0;
    margin-right: 0.1rem;
    margin-top: 0.0625rem;
}

.placeholderSpace {
    visibility: hidden;
}