.eventItem {
    border-radius: 1rem;
    border: 1px solid var(--Plii-Secondary, #2F1C0A);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.eventItemTitle {}

.eventItemProfilePictureContainer {
    border-radius: 0.5rem;
    background: #B5B6B5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 2.625rem;
    width: 2.625rem;
    margin-bottom: 1rem;
}

.eventItemProfilePicture {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
}

.eventItemCreator {
    color: #2F1C0A;
    text-align: right;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 0.75rem */
}

.digestItem {
    width: 100%;
    height: 100%;
}

.eventItemTime {
    text-align: right;
}

.mainEventItem {
    background: #FFA929;
}

.otherEventItem {
    background: #FBEFDA;
}

.spouseEventItem {
    background: #FFDD6B;
}

.spouseRelatedEventItem {
    background: repeating-linear-gradient(135deg,
            /* Angle for the diagonal lines */
            #FFA929,
            /* Light color for the line */
            #FFA929 2px,
            /* Thickness of the light line */
            #FFDD6B 2px,
            /* Space between lines */
            #FFDD6B 16px
            /* Total pattern size */
        );
    /* background: #FFA929; */
}

.relatedEventItem {
    background: repeating-linear-gradient(135deg,
            /* Angle for the diagonal lines */
            #FFA929,
            /* Light color for the line */
            #FFA929 2px,
            /* Thickness of the light line */
            #FBEFDA 2px,
            /* Space between lines */
            #FBEFDA 16px
            /* Total pattern size */
        );
    /* background: #FFA929; */
}

.eventItemLeftSection {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.eventItemRightSection {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}