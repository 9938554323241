.dropdown {
    position: relative;
    font-family: Inter;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4rem;
    width: 100%;
}

.actionDropdown.placeholder {
    color: #2F1C0A;
}

.optionRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.69rem;
    width: 100%;
    padding: 1rem 1rem;
    box-sizing: border-box;
    transition: "all 0.3s ease-in-out";
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    /* 0.8625rem */
}

.topDivider {
    border-top: 0.5px solid #8C8C8C;
}

.optionRow:hover {
    background-color: #2F1C0A;
    color: #fff;
}


.profilePicture {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 0.375rem;
    object-fit: cover;
    border: none;
}

.icon {
    width: 0.875rem;
    height: 0.875rem;
}

.name {
    margin-left: 0;
}