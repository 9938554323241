/* Full-height container */
.container {
  text-align: center;
  height: 100%;
  /* Full height of the viewport */
  min-height: 100vh;
  background: var(--Plii-Background, #F1DABA);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(-1 * env(safe-area-inset-top));
  /* Center content vertically */
}

/* Center logoContent without using fixed margins */
.logoContent {
  margin: 0 auto;
  /* Center horizontally */
}

.logo {
  width: 100%;
  height: 8.345rem;
}