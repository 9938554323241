.nameFields {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.nameFieldInputs {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.errorText {
    min-height: 1.5rem;
    align-items: flex-start;
    color: var(--Plii-Red);
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 0.2rem;
    display: flex;
    /* Optional: Show "..." if text is too long */
    /* 0.9rem */
}

.errorIcon {
    margin-top: 0.125rem;
    width: 0.75rem;
    height: 0.75rem;
    flex-shrink: 0;
    margin-right: 0.1rem;
}

.placeholderSpace {
    visibility: hidden;
}