.eventItem {
    border-radius: 1rem;
    border: 1px solid var(--Plii-Secondary, #2F1C0A);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    color: #2F1C0A;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.eventItemCardRow {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    width: 100%;
}

.smallerPaddingRow {
    margin-top: 0;
}

.eventItemProfilePictureContainer {
    border-radius: 0.5rem;
    background: #B5B6B5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 2.625rem;
    width: 2.625rem;
    margin-bottom: 1rem;
}

.eventItemProfilePicture {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
}

.caretakerRequestsContainer {
    border-radius: 0.375rem;
    background: #2F1C0A;
    color: #FBEFDA;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding: 0.31rem;
    /* 0.9rem */
}

.eventItemCreator {
    color: #2F1C0A;
    text-align: right;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 0.75rem */
}

.digestItem {
    width: 100%;
    height: 100%;
}

.eventItemTime {
    text-align: right;
}

.mainEventItem {
    background: #FFA929;
}

.otherEventItem {
    background: #FBEFDA;
}

.spouseEventItem {
    background: #FFDD6B;
}

.spouseRelatedEventItem {
    background: repeating-linear-gradient(135deg,
            /* Angle for the diagonal lines */
            #FFA929,
            /* Light color for the line */
            #FFA929 2px,
            /* Thickness of the light line */
            #FFDD6B 2px,
            /* Space between lines */
            #FFDD6B 16px
            /* Total pattern size */
        );
    /* background: #FFA929; */
}

.relatedEventItem {
    background: repeating-linear-gradient(135deg,
            /* Angle for the diagonal lines */
            #FFA929,
            /* Light color for the line */
            #FFA929 2px,
            /* Thickness of the light line */
            #FBEFDA 2px,
            /* Space between lines */
            #FBEFDA 16px
            /* Total pattern size */
        );
    /* background: #FFA929; */
}

.eventItemLeftSection {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.eventItemRightSection {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.interactionButtonContainer {
    display: flex;
    /* Use flexbox for alignment */
    justify-content: flex-end;
    /* Push the button to the right */
    align-items: center;
    /* Vertically center the button */
    width: 100%;
    /* Ensure the container takes up the full width */
}

.interactionButton {
    background: none;
    /* Remove default button background */
    border: none;
    /* Remove default button border */
    padding: 0;
    /* Add some padding */
    border-radius: 50%;
    /* Make it circular if desired */
    cursor: pointer;
    /* Show pointer cursor */
    transition: background-color 0.3s ease;
    /* Add hover effect */
    display: flex;
    justify-content: center;
    align-items: center;
}