.profileViewContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 4.95rem;
}

.logoutContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.logOutButtonContainer {
    display: flex;
    width: 2.25rem;
    height: 2.25rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background: #FF6632;
}

.logoutButton {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
}

.logOutText {
    margin-left: 0.75rem;
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    /* 0.8625rem */
}

.nameAndEmailContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.name {
    color: var(--Plii-Secondary, #2F1C0A);
    text-align: center;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 1.375rem */
}

.email {
    margin-top: 0.25rem;
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.05rem */
}

.composeIcon {
    width: 1rem;
    height: 1rem;
}

.settingButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.settingButton {
    width: 9.5625rem;
    padding: 0.5rem;
    padding-right: 1rem;
    color: #FBEFDA;
    text-align: center;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    align-items: center;
    justify-content: flex-end;
    gap: 1.25rem;
    /* 1.225rem */
}