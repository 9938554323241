/* Ensure the container fits the screen */
.form-fullscreen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full viewport height */
  width: 100%; /* Full viewport width */
  overflow: hidden; /* Prevent internal scrolling */
}

/* Typeform embed specific styling */
.form-fullscreen-container div[data-tf-live] {
  width: 100%;
  height: 100%;
  max-height: 100%; /* Restrict to viewport height */
  box-sizing: border-box; /* Prevent padding or borders from affecting size */
}
