.settingsContainer {
    margin-top: 4.95rem;
    display: flex;
}

.settingsRow {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #2F1C0A;
}

.settingName {
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    /* 0.8625rem */
}

.composeIcon {
    width: 1rem;
    height: 1rem;
}