@font-face {
  font-family: 'Inter';
  src: url('./fonts/InterVariable.woff2') format('woff2');
  font-weight: 100 900;
  /* Supports multiple font weights */
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/InterVariable-Italic.woff2') format('woff2');
  font-weight: 100 900;
  font-style: italic;
}

@font-face {
  font-family: 'GT Maru Mega Maxi';
  src: url('./fonts/GT-Maru-Mega-Maxi-Trial.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Maru Mega Trial';
  src: url('./fonts/GT-Maru-Mega-Trial-VF.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


html {
  background-color: white;
}

body {
  background: #FBEFDA;
  color: #2F1C0A;
}

html,
body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  transition: height 0.3s ease-out;
}

#root {
  min-height: 100dvh !important;
  overflow: hidden !important;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  /* Make the root take full viewport height */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  --Plii-Background: #FBEFDA;
  --Plii-Secondary: #2F1C0A;
  --Plii-Red: #FF6632;
  background: var(--Plii-Background, #FBEFDA);
}

.iosTopPadding {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-style: normal;
}

input {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}