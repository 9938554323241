.App {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  background-color: #f0f0f0;
}

.chat-box {
  width: 18.75rem;
  /* Converted from 300px */
  padding: 1.25rem;
  /* Converted from 20px */
  border: 0.0625rem solid #ccc;
  /* Converted from 1px */
  background-color: white;
  border-radius: 0.625rem;
  /* Converted from 10px */
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1.25rem;
  /* Converted from 20px */
}

.yes-button,
.no-button {
  padding: 0.625rem 1.25rem;
  /* Converted from 10px 20px */
  border: none;
  border-radius: 0.3125rem;
  /* Converted from 5px */
  cursor: pointer;
  font-size: 1rem;
  /* Converted from 16px */
}

.yes-button {
  background-color: #4CAF50;
  color: white;
}

.no-button {
  background-color: #f44336;
  color: white;
}

.response {
  margin-top: 1.25rem;
  /* Converted from 20px */
  font-size: 1rem;
  /* Converted from 16px */
  color: #555;
}