/* Common styles shared by both flows */
.uploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.imageWrapper {
    position: relative;
    /* For positioning the camera button */
    display: inline-block;
    /* Keeps the container sized correctly */
}

.imageContainer {
    position: relative;
    width: 9.375rem;
    /* Profile picture size */
    height: 9.375rem;
    overflow: hidden;
    /* Ensures no content overflows the circular container */
    /* border-radius: 50%; */
    /* Enforces the circular shape for the container */
    /* border: 0.25rem solid #ccc; */
    border-radius: 2.0625rem;
    border: 6px solid #FFF;
    /* Adds a consistent border */
    background-color: #f4f4f4;
    /* Fallback background for empty state */
}

.imagePreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Maintain aspect ratio and cover the area */
}

.cropPreview {
    max-width: 200px;
    max-height: 200px;
    /* Maintain aspect ratio and cover the area */
}

.cameraButton {
    position: absolute;
    bottom: -1rem;
    /* Adjust outside the image container */
    right: -1rem;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
    background-color: #ffffff;
    border: 0.1875rem solid var(--Plii-Secondary, #2F1C0A);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.cameraIcon {
    width: 1.25rem;
    height: 1.25rem;
}


.uploadButton {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
}

.skipButton {
    background-color: transparent;
    color: #6c757d;
    border: 1px solid #6c757d;
    border-radius: 20px;
    padding: 8px 20px;
    margin-top: 1rem;
    cursor: pointer;
}

/* Specific styles for Onboarding */
.onboardingContainer {
    color: white;
    padding: 2rem;
}

.onboardingButton {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
}

/* Specific styles for Profile Settings */
.profileContainer {
    background-color: #FBEFDA;
    color: black;
    padding: 1.75rem;
}

.profileButton {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
}