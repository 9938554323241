.digestContainer {
    padding-top: 4.95rem;
    margin: 0 auto;
    /* Adjust as needed for more top spacing */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center align carousel content */
    width: 100%;
    box-sizing: border-box;
    position: relative;
    flex-grow: 1;
}

.loadingScreen {
    opacity: 0.7;
}

.digestLoadingScreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    text-align: center;
}

.spinnerContainer {
    display: flex;
    width: 100%;
    justify-content: center;
}

.spinner {
    border: 3px solid var(--Plii-Secondary);
    border-top: 3px solid white;
}

.listViewButton {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 1.575rem */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
}